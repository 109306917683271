// frontend/src/components/Register.js

import React, { useState } from 'react';
import { registerUser } from '../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Register = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!name.trim() || !email.trim()) return;
    try {
      const response = await registerUser({ email, name });
      console.log('Registration response:', response.data);
      onSuccess();
      toast.success('Registration successful!');
      navigate('/');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      toast.error('Registration failed. Please try again.');
    }
  };

  return (
    <form onSubmit={handleRegister} className="modal-form">
      <div className="form-group">
        <label>Full Name</label>
          <input
            name="text"
            id="name"
            value={name}
            maxLength={40}
            onChange={(e) => setName(e.target.value)}
            required
          />
      </div>
        <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete="email"
        />
      </div>
      <div className="form-group checkbox-group">
        <input
          type="checkbox"
          id="terms"
          name="terms"
          required
        />
        <label htmlFor="terms">
          Agree to <a href="/terms" target="_blank" rel="noopener noreferrer">Terms</a>
        </label>
      </div>
      <button type="submit" className="submit-button">Register</button>
    </form>
  );
};

export default Register;