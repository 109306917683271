import React, { useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Modal from 'react-modal';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


// Import default marker icon
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import Login from '../components/Login';
import Register from '../components/Register';
import {useAuth} from "../contexts/AuthContext";


// Fix the default icon path issue
let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

// Set the root element for the modal (important for accessibility)
Modal.setAppElement('#root');

function Home() {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const openLoginModal = () => setIsLoginModalOpen(true);
  const closeLoginModal = () => setIsLoginModalOpen(false);

  const openRegisterModal = () => setIsRegisterModalOpen(true);
  const closeRegisterModal = () => setIsRegisterModalOpen(false);

  const {user,setUser} = useAuth();

  return (
      <div className="map-container">
        <MapContainer center={[27.391, -82.451]} zoom={13} style={{ height: '100%', width: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[27.391, -82.451]}>
            <Popup>Lakewood Ranch, Florida</Popup>
          </Marker>
        </MapContainer>

        <div className="buttons-container">
        {!user ? 
        <>
          <button className="cta-button" onClick={openLoginModal}>Login</button>
          <button className="cta-button" onClick={openRegisterModal}>Register</button>
        </>
        :
        <button className="cta-button" onClick={()=>{
          localStorage.removeItem("token");
          setUser(null);
        }}>Logout</button>
        }
        </div>
        

        {/* Login Modal */}
        <Modal
          isOpen={isLoginModalOpen}
          onRequestClose={closeLoginModal}
          contentLabel="Login Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <button onClick={closeLoginModal} className="modal-close">&times;</button>
          <h2>Login</h2>
          <Login onSuccess={closeLoginModal} /> {/* Pass closeLoginModal as onSuccess */}
        </Modal>

        {/* Register Modal */}
        <Modal
          isOpen={isRegisterModalOpen}
          onRequestClose={closeRegisterModal}
          contentLabel="Register Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <button onClick={closeRegisterModal} className="modal-close">&times;</button>
          <h2>Register</h2>
          <Register onSuccess={closeRegisterModal} /> {/* Pass closeRegisterModal as onSuccess */}
        </Modal>
      </div>
  );
}

export default Home;
