// src/api.js

import axios from 'axios';

// Set the base URL to your new API endpoint
const BASE_URL = process.env.NODE_ENV === "production" ? 'https://api.wiseaddress.com' : "http://localhost:8000";

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to register a new user
export const registerUser = (userData) => {
  return axios.post(`${BASE_URL}/users/register`, userData);
};

// Function to log in a user
export const loginUser = (userData) => {
  return axios.post(`${BASE_URL}/users/login`, userData);
};

// Function to retrieve all markers
export const getMarkers = () => {
  return axios.get(`${BASE_URL}/markers`);
};

// Function to add a new marker
export const addMarker = (markerData) => {
  return axios.post(`${BASE_URL}/markers`, markerData);
};

// Function to upload a CSV of POIs
export const uploadPoiCsv = (formData) => {
  return axios.post(`${BASE_URL}/pois/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

// Function to fetch all POIs
export const getPois = () => {
  return axios.get(`${BASE_URL}/pois`);
};
