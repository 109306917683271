import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MagicLinkHandler from './pages/MagicLinkVerify';
import Home from './pages/Home';
import PoiCsvUpload from './components/PoiCsvUpload';
import MapComponent from './components/Map';

const AllRoutes = () => {
  return (
    <Router>
        <Routes>
          <Route path="/verify-magic-link" element={<MagicLinkHandler />} />
          <Route path="/dashboard" element={<h1>Dashboard</h1>} /> {/* New route for dashboard */}
          <Route path="/pois" element={<PoiCsvUpload />} /> {/* New route for dashboard */}
          <Route path="/check-pois" element={<MapComponent />} /> {/* New route for dashboard */}
          <Route path="/welcome" element={<h1>Welcome to WiseAddress</h1>} /> {/* New route for welcome */}
          <Route path="/" element={<Home />} /> {/* New route for welcome */}
        </Routes>
    </Router>
  )
}

export default AllRoutes