// frontend/src/components/Map.js

import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet';
import { getPois } from '../api'; // Import the new getPois function

const MapComponent = () => {
  const [pois, setPois] = useState([]);

  useEffect(() => {
    const fetchPois = async () => {
      try {
        const response = await getPois();
        setPois(response.data);
      } catch (error) {
        console.error('Error fetching POIs:', error);
      }
    };

    fetchPois();
  }, []);

  const MapEvents = () => {
    useMapEvents({
      click: (event) => {
        console.log(event.latlng);
      },
    });
    return null;
  };

  return (
    <MapContainer 
    center={[39.8283, -98.5795]}
    zoom={4} // Zoomed out to show entire USA
    style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />


      {pois.map((poi, index) => (
        <Marker key={index} position={[poi.latitude, poi.longitude]}>
          <Popup>{poi.name} ({poi.category})</Popup>
        </Marker>
      ))}

      <MapEvents />
    </MapContainer>
  );
};

export default MapComponent;
