// Login.js
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { loginUser } from '../api';

const Login = ({ onSuccess }) => {
  const [email, setEmail] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser({ email });
      toast.success('Email sent for you to login');
      onSuccess();
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <form onSubmit={handleLogin} className="modal-form">
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete="email"
        />
      </div>
      <button type="submit" className="submit-button">Login</button>
    </form>
  );
};

export default Login;
