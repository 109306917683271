import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { useAuth } from '../contexts/AuthContext';

const MagicLinkHandler = () => {
  const navigate = useNavigate();
  const {setUser} = useAuth();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    if (!token) return;
    (async()=>{
        try {
          const {data} = await api.get(`/users/verify-magic-link?token=${token}`);
          console.log(data,'data')
          localStorage.setItem("token",data.access_token);
          setUser({token:data.access_token});
          navigate("/")
        }
        catch {
            navigate('/login');
        }
        return;
    })()

    navigate('/login');
  }, [window?.location?.search]);

  return <div>Processing your login...</div>;
};

export default MagicLinkHandler;
